import dynamic from "next/dynamic";
const ArticleSlider = dynamic(() => import("../../organisms/slider").then((mod) => mod.ArticleSlider));

export function ParagraphRecentNews({paragraph, ...props}) {
  return (
    <>
      {paragraph?.articles?.length ? (
        <ArticleSlider articles={paragraph?.articles}/>
      ): <></>}
    </>
  )
}